import { alpha, darken, Theme } from '@mui/material';
import config from '@shared/config';
import { blue, darkBlue } from '@shared/theme/palette';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    cancelDialog: true;
    secondaryOrange: true;
    secondaryLightBlue: true;
    secondaryDarkBlue: true;
  }
}

const Button = (theme: Theme) => {
  return {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: config.theme.disableRipple,
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: config.theme.elevation === 0,
        variant: 'contained',
      },
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: 32,
        },
        containedInfo: {
          backgroundColor: theme.palette.common.white,
          color: theme.palette.primary.main,
        },
        outlinedSizeLarge: {
          borderWidth: .5,
          '&:hover': {
            borderWidth: .5,
          },
        },
      },
      variants: [{
        props: { color: 'secondary', variant: 'outlined' },
        style: {
          borderColor: theme.palette.mode === 'light' ? theme.palette.secondary.main : theme.palette.grey[300],
          color: theme.palette.mode === 'light' ? theme.palette.secondary.main : theme.palette.text.primary,
          ':hover': {
            borderColor: theme.palette.mode === 'light' ? theme.palette.secondary.main : theme.palette.grey[500],
          },
        },
      },
      {
        props: { color: 'primary', variant: 'contained' },
        style: {
          '.MuiSvgIcon-root': {
            color: theme.palette.mode === 'light' ? theme.palette.grey[100] : darkBlue,
          },
        },
      },
      {
        props: { variant: 'cancelDialog' },
        style: {
          borderRadius: 0,
          color: theme.palette.secondary.main,
          backgroundColor: theme.palette.grey[200],
          '&:hover': {
            backgroundColor: theme.palette.common.white,
          },
        },
      },
      {
        props: { variant: 'secondaryOrange' },
        style: {
          backgroundColor: '#FF9F20',
          borderRadius: '20px',
          color: '#fff',
          fontSize: 18,
          fontWeight: 700,
          height: '60px',
          textTransform: 'uppercase',
          '&:hover': {
            backgroundColor: '#FF9F20',
          },
        },
      },
      {
        props: { variant: 'secondaryLightBlue' },
        style: {
          backgroundColor: '#00B3F2',
          borderRadius: '8px',
          color: '#fff',
          fontSize: 16,
          fontWeight: 700,
          height: '45px',
          textTransform: 'uppercase',
          '&:hover': {
            backgroundColor: '#00B3F2',
          },
        },
      },
      {
        props: { variant: 'secondaryDarkBlue' },
        style: {
          backgroundColor: '#072742',
          color: '#fff',
          fontWeight: 400,
          fontSize: 12,
          height: '48px',
          '&:hover': {
            backgroundColor: '#072742',
          },
        },
      }],
    },
    MuiButtonGroup: {
      defaultProps: {
        variant: 'contained',
      },
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
        groupedContainedPrimary: {
          borderColor: 'transparent !important',
          '&.Mui-disabled': {
            backgroundColor: alpha(theme.palette.primary.main, 0.5),
            color: alpha(theme.palette.primary.contrastText, 0.5),
          },
          '&.MuiButton-textSecondary': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            // '&:hover': {
            //   backgroundColor: theme.palette.primary.dark,
            //   color: theme.palette.primary.contrastText,
            // },
          },
          '&.MuiButton-containedInfo': {
            '&:hover': {
              color: theme.palette.primary.contrastText,
            },
          },
        },
        groupedContainedSecondary: {
          // '&.MuiButton-textPrimary': {
          //   backgroundColor: theme.palette.common.white,
          //   color: theme.palette.primary.main,
          //   '&:hover': {
          //     backgroundColor: theme.palette.primary.dark,
          //     color: theme.palette.primary.contrastText,
          //   },
          // },
          // '&.MuiButton-textSecondary': {
          //   '&:hover': {
          //     backgroundColor: darken(theme.palette.secondary.dark, 0.5),
          //     color: theme.palette.grey[200],
          //   },
          // },
        },
      },
      // TODO: Add upper styles to variants
      variants: {
      },
    },
    MuiIconButton: {
      variants: [{
        props: { color: 'secondary' },
        style: {
          color: theme.palette.mode === 'light' ? theme.palette.secondary.main : theme.palette.grey[100],
        },
      }],
    },
    MuiLoadingButton: {
      variants: [{
        props: { color: 'secondary', variant: 'outlined' },
        style: {
          color: theme.palette.grey[100],
        },
      },
      {
        props: { color: 'secondary', variant: 'contained' },
        style: {
          color: theme.palette.primary.main,
          '.MuiSvgIcon-root': {
            color: theme.palette.primary.main,
          },
        },
      },
      {
        props: { color: 'error', variant: 'contained' },
        style: {
          '.MuiSvgIcon-root': {
            color: theme.palette.grey[100],
          },
        },
      }],
    },
  };
};

export default Button;
